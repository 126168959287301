<template>
  <div class="home">
    <el-container style="height: calc(100vh - 40px)">
      <el-aside :width="isCollapse2 ? '64px' : '256px'" class="silde">
        <!-- 1管理员 2国家 6省 3市 4机构 -->
        <el-menu :collapse-transition="false" :default-openeds="openedsArr"
          :default-active="activeMenu" @select="handleSelect" :collapse="isCollapse2"
          :class="isCollapse1 ? 'close-menu' : ''">
          <el-menu-item :index="item.path" v-for="item in menuList" v-if="!item.children">
            <img class="menu-icon" v-if="item.path.includes(activeMenu)" :src="item.iconA" alt="">
            <img class="menu-icon" v-else :src="item.icon" alt="">
            <span slot="title" :style="{ color: item.path.includes(activeMenu) ? '#409EFF' : isCollapse1 ? '#fff':'#303133' }">{{ item.text }}</span>
          </el-menu-item>
          <el-submenu :index="item.path" v-for="item in menuList" v-if="item.children">
            <template slot="title">
              <img class="menu-icon" v-if="item.children.find(vv=>vv.path==activeMenu)" :src="item.iconA" alt="">
              <img class="menu-icon" v-else :src="item.icon" alt="">
              <span slot="title" :style="{ color: item.children.find(vv=>vv.path==activeMenu) ? '#409EFF' : '#303133' }">{{ item.text }}</span>
            </template>
            <el-menu-item :index="v.path" v-for="v in item.children"
              :style="{ color: activeMenu == v.path ? '#409EFF' : '#303133' }">{{ v.text }}</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container class="myContainer">
        <el-header class="header">
          <router-link :to="role == 4 ? '/':'/chart'" tag="div" v-if="!isCollapse2" >
            <img class="logo" :class="isCollapse2 ? 'collapse_logo' : ''" src="../assets/index/logo_index.png" />
          </router-link>
          <div class="title">
            <i class="collapse" :class="isCollapse2 ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="toggleMenu"></i>
            <div style="display: flex; align-items: center; justify-content: flex-end;">
              <div style="margin-left: 20px;" class="qa" @click="drawer = true">
                <img class="qa-icon" :src="drawer
                  ? require('../assets/index/qa_icon.png')
                  : require('../assets/index/qa_icon_dis.png')
                  " alt="" />
                <span :style="{ color: drawer ? '#0083F5' : '#000' }">帮助</span>
              </div>
            </div>
          </div>
          <el-dropdown class="user" @command="logout">
            <div class="user-wrap">
              <el-avatar class="avatar" :size="38" fit="cover" :src="circleUrl" @error="errorHandler" placement="bottom">
                <img style="width: 100%; height: 100%" :src="require('../assets/index/avatar.png')" alt="" /></el-avatar>
              <span class="user-name ellipsis">{{ name }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a" v-if="role == 4"><span>机构信息</span>
              </el-dropdown-item>
              <el-dropdown-item command="b"><span>修改密码</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item command="d" v-if="role == 4"><span>换绑手机</span>
            </el-dropdown-item> -->
              <el-dropdown-item command="c"><span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <div class="myHeader"></div>
        <el-main class="main"  :class="{chartMain:isChart}">
          <router-view ref="views" />
        </el-main>
      </el-container>
    </el-container>
    <el-drawer title="" size="368px" :visible.sync="drawer" direction="rtl">
      <div class="drawer">
        <!-- <h3>用户使用教程视频</h3>
      <img :src="require('../assets/index/qa_video_cover.png')" alt="" @click="dialogVisible = true" /> -->
        <!-- <div style="font-size: 22px;font-weight:600;margin: 0;">托育SaaS云<a href="http://tuoyusaas.com/" target="_blank" style="color:#0083F5;font-size: 22px;text-align:center">http://tuoyusaas.com/</a></div>
      <el-divider></el-divider> -->
        <h3>使用帮助</h3>
        <p class="book pdf">
          <a href="https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/files/%E6%89%98%E8%82%B2%E6%9C%BA%E6%9E%84%E8%87%AA%E8%AF%84-%E5%B7%A5%E4%BD%9C%E6%89%8B%E5%86%8C7.5.pdf"
            target="_blank">机构自评手册</a>
          <span></span>
          <a href="https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/files/%E4%B8%93%E5%AE%B6%E7%9D%A3%E5%AF%BC%E4%BB%96%E8%AF%84-%E5%B7%A5%E4%BD%9C%E6%89%8B%E5%86%8C7.5.pdf"
            target="_blank">专家他评手册</a>
        </p>
        <h4>老师您好，我是您的评估专家</h4>
        <div class="user">
          <p style="margin: 10px 0;">评估实施咨询电话：</p>
          <p style="font-size: 22px;color:#0083F5;font-weight:600;margin: 0;">010-66023794</p>
          <p style="font-size: 14px;color:#666; margin: 10px 0 30px">工作日：8:30-11:30 ~ 13:30-17:00</p>
        </div>
        <h4>老师您好，我是您的技术客服</h4>
        <div class="user">
          <div class="avatar">
            <img :src="require('../assets/index/qa_avatar.png')" alt="" />
            <p style="margin: 0">杜老师</p>
          </div>
          <p>您在使用平台的过程中出现任何问题，随时与我们联系。</p>
          <img class="qr" :src="require('../assets/index/qa_qr.png')" alt="" />
          <p class="phone">客服电话：010-57182596</p>
        </div>
        <!-- <p class="book pdf">
          <a href="https://www.tuoyufuwu.org.cn/detail.html?id=1431&type=article" target="_blank">
            关于发布推荐性卫生行业标准
            <br> 《托育机构质量评估标准》的通告
            <br> (国卫通〔2023〕13号)
          </a>
        </p> -->
      </div>
    </el-drawer>
    <el-dialog title="用户操作视频" :visible.sync="dialogVisible" @close="dialogClose" width="70%">
      <video class="video" controls v-if="dialogVideoUrl" width="100%" :src="dialogVideoUrl" alt="" />
    </el-dialog>
    <el-footer id="footer" style="height: 40px;">
      <span>ICP备案编号：京ICP备13043694号</span>
      <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049964" target="_blank"
          style="text-decoration: none;cursor: pointer;">
          <img src="../assets/index/beian.png" style="margin-right:4px;" />
          京公网安备 11010502049964号</a></span>
      <span>技术支持：京学集团</span>
    </el-footer>
  </div>
</template>

<script>
let pagewidth = document.body.clientWidth;
import chartImg from '@/assets/menu/chart.png'
import chartImgA from '@/assets/menu/chart_a.png'

import countryImg from '@/assets/menu/country.png'
import countryImgA from '@/assets/menu/country_a.png'
import orgImg from '@/assets/menu/list.png'
import orgImgA from '@/assets/menu/list_a.png'
import orgGLImg from '@/assets/menu/org.png'
import orgGLImgA from '@/assets/menu/org_a.png'
import cityImg from '@/assets/menu/city.png'
import cityImgA from '@/assets/menu/city_a.png'
import provinceImg from '@/assets/menu/mange.png'
import provinceImgA from '@/assets/menu/mange_a.png'
import adminImg from '@/assets/menu/admin.png'
import adminImgA from '@/assets/menu/admin_a.png'
export default {
  name: "Home",
  data() {
    return {
      menuAll:{
        // 超级管理员1
        1:[{
        text: '质量评估态势',
        iconA: chartImgA,
        icon: chartImg,
        path:'chart',
      },{
        text: '机构自评管理',
        iconA: orgImgA,
        icon: orgImg,
        path:'table_org',
        children:[{
          text: '未审核列表',
          path:'school_submitted',
        },{
          text: '评审中列表',
          path:'school_review',
        },{
          text: '已评审列表',
          path:'school_reviewed',
        }]
      },{
        text: '国家级督评管理',
        iconA: countryImgA,
        icon: countryImg,
        path:'table_country',
        children:[{
          text: '创建督评',
          path:'country_create',
        },{
          text: '评审中列表',
          path:'country_review',
        },{
          text: '已评审列表',
          path:'country_reviewed',
        }]
      },{
        text: '省级他评管理',
        iconA: provinceImgA,
        icon: provinceImg,
        path:'table_province',
        children:[{
          text: '评审中列表',
          path:'province_review',
        },{
          text: '已评审列表',
          path:'province_reviewed',
        }]
      },{
        text: '市级他评管理',
        iconA: cityImgA,
        icon: cityImg,
        path:'table_city',
        children:[{
          text: '评审中列表',
          path:'city_review',
        },{
          text: '已评审列表',
          path:'city_reviewed',
        }]
      },{
        text: '机构管理',
        iconA: orgGLImgA,
        icon: orgGLImg,
        path:'table_orgGL',
        children:[{
          text: '机构列表',
          path:'organ_list',
        },{
          text: '机构用户列表',
          path:'user_list',
        }]
      },{
        text: '管理员管理',
        iconA: adminImgA,
        icon: adminImg,
        path:'table_admin',
        children:[{
          text: '管理员列表',
          path:'admin_list',
        }]
      }],
      // 国家2
      2:[{
        text: '质量评估态势',
        iconA: chartImgA,
        icon: chartImg,
        path:'chart',
      },{
        text: '机构自评管理',
        iconA: orgImgA,
        icon: orgImg,
        path:'table_org',
        children:[{
          text: '未审核列表',
          path:'school_submitted',
        },{
          text: '评审中列表',
          path:'school_review',
        },{
          text: '已评审列表',
          path:'school_reviewed',
        }]
      },{
        text: '国家级督评管理',
        iconA: countryImgA,
        icon: countryImg,
        path:'table_country',
        children:[{
          text: '创建督评',
          path:'country_create',
        },{
          text: '评审中列表',
          path:'country_review',
        },{
          text: '已评审列表',
          path:'country_reviewed',
        }]
      },{
        text: '省级他评管理',
        iconA: provinceImgA,
        icon: provinceImg,
        path:'table_province',
        children:[{
          text: '评审中列表',
          path:'province_review',
        },{
          text: '已评审列表',
          path:'province_reviewed',
        }]
      },{
        text: '市级他评管理',
        iconA: cityImgA,
        icon: cityImg,
        path:'table_city',
        children:[{
          text: '评审中列表',
          path:'city_review',
        },{
          text: '已评审列表',
          path:'city_reviewed',
        }]
      },{
        text: '机构管理',
        iconA: orgGLImgA,
        icon: orgGLImg,
        path:'table_orgGL',
        children:[{
          text: '机构列表',
          path:'organ_list',
        },{
          text: '机构用户列表',
          path:'user_list',
        }]
      }],
      // 市级
        3:[{
        text: '质量评估态势',
        iconA: chartImgA,
        icon: chartImg,
        path:'chart',
      },{
        text: '机构自评管理',
        iconA: orgImgA,
        icon: orgImg,
        path:'table_org',
        children:[{
          text: '已提交列表',
          path:'school_submitted',
        },{
          text: '评审中列表',
          path:'school_review',
        },{
          text: '已评审列表',
          path:'school_reviewed',
        }]
      },{
        text: '市级他评管理',
        iconA: cityImgA,
        icon: cityImg,
        path:'table_city',
        children:[{
          text: '创建他评',
          path:'city_create',
        },{
          text: '评审中列表',
          path:'city_review',
        },{
          text: '已评审列表',
          path:'city_reviewed',
        }]
      }],
      // 托育机构4
        4:[{
        text: '主页',
        iconA: chartImgA,
        icon: chartImg,
        path:'index',
      },{
        text: '机构自评管理',
        iconA: orgImgA,
        icon: orgImg,
        path:'table_org',
        children:[{
          text: '未提交列表',
          path:'school_not_submit',
        },{
          text: '已提交列表',
          path:'school_submitted',
        },{
          text: '已评审列表',
          path:'school_reviewed',
        }]
      },{
        text: '市级他评管理',
        iconA: cityImgA,
        icon: cityImg,
        path:'table_city',
        children:[{
          text: '已评审列表',
          path:'city_reviewed',
        }]
      },{
        text: '省级他评管理',
        iconA: provinceImgA,
        icon: provinceImg,
        path:'table_province',
        children:[{
          text: '已评审列表',
          path:'province_reviewed',
        }]
      },{
        text: '国家级督评管理',
        iconA: countryImgA,
        icon: countryImg,
        path:'table_country',
        children:[{
          text: '已评审列表',
          path:'country_reviewed',
        }]
      }],
      // 省级6
      6:[{
        text: '质量评估态势',
        iconA: chartImgA,
        icon: chartImg,
        path:'chart',
      },{
        text: '机构自评管理',
        iconA: orgImgA,
        icon: orgImg,
        path:'table_org',
        children:[{
          text: '已提交列表',
          path:'school_submitted',
        },{
          text: '评审中列表',
          path:'school_review',
        },{
          text: '已评审列表',
          path:'school_reviewed',
        }]
      },{
        text: '省级他评管理',
        iconA: provinceImgA,
        icon: provinceImg,
        path:'table_province',
        children:[{
          text: '创建他评',
          path:'province_create',
        },{
          text: '评审中列表',
          path:'province_review',
        },{
          text: '已评审列表',
          path:'province_reviewed',
        }]
      },{
        text: '市级他评管理',
        iconA: cityImgA,
        icon: cityImg,
        path:'table_city',
        children:[{
          text: '评审中列表',
          path:'city_review',
        },{
          text: '已评审列表',
          path:'city_reviewed',
        }]
      }],
      },
      menuList:[],
      circleUrl: "",
      isCollapse1: false,
      isCollapse2: pagewidth < 1800,
      drawer: false,
      role: "",
      name: '',
      username: '',
      activeMenu: 'chart',
      dialogTitle: '',
      dialogVisible: false,
      dialogVideoUrl: 'dialogVideoUrl',
      openedsArr:['table_1', 'table_2', 'table_3', 'table_4', 'table_5']
    };
  },
  computed: {
    isTableActive() {
      return this.activeMenu == "school_not_submit" || this.activeMenu == "school_submitted" || this.activeMenu == "school_reviewed" || this.activeMenu == "school_review" || this.activeMenu == "city_create" || this.activeMenu == "city_reviewed" || this.activeMenu == "city_reviewed" || this.activeMenu == "country_create" || this.activeMenu == "country_reviewed" || this.activeMenu == "country_reviewed";
    },
    isSchoolType() {
      let t = this.activeMenu;
      return t == "school_not_submit" || t == "school_submitted" || t == "school_reviewed" || t == "school_review";
    },
    isProvinceType() {
      let t = this.activeMenu;
      return t == "province_create" || t == "province_review" || t == "province_reviewed";
    },
    isCityType() {
      let t = this.activeMenu;
      return t == "city_create" || t == "city_review" || t == "city_reviewed";
    },
    isCountryType() {
      let t = this.activeMenu;
      return t == "country_create" || t == "country_review" || t == "country_reviewed";
    },
    isAdminActive() {
      return this.activeMenu == "admin_list" || this.activeMenu == "unit_list"
    },
    isOrganActive() {
      return this.activeMenu == "organ_list" || this.activeMenu == "user_list"
    },
    isIndex() {
      return this.activeMenu == "index"
    },
    isChart() {
      return this.activeMenu == "chart"
    },
  },
  methods: {
    dialogClose() {
      this.dialogVisible = false;
    },
    errorHandler() {
      return false;
    },
    toggleMenu() {
      if (this.isCollapse1 == true) {
        this.isCollapse1 = false;
        setTimeout(() => {
          this.isCollapse2 = false;
          if(this.$route.name=='chart'){
            this.$refs.views.changeMenu()
          }
        }, 0);
      } else {
        this.isCollapse1 = true;
        setTimeout(() => {
          this.isCollapse2 = true;
          if(this.$route.name=='chart'){
            this.$refs.views.changeMenu()
          }
        }, 0);
      }
      
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    handleSelect(key, keyPath) {
      if (key == 'city_create' || key == 'country_create'|| key == 'province_create') {
        this.activeMenu = key;
        console.log(key, keyPath, this.activeMenu);
        if (key == 'city_create') {
          this.$router.push('/table/city_review');
        } else if(key == 'country_create') {
          this.$router.push('/table/country_review');
        } else if(key == 'province_create') {
          this.$router.push('/table/province_review');
        }
        setTimeout(() => {
          this.$router.push('/inspect/' + key);
        }, 0);
        return
      }
      let path = [...keyPath];
      if (path[0].indexOf('_') != -1) {
        path[0] = path[0].split('_')[0];
      }
      this.$router.push("/" + path.join("/"));
      this.activeMenu = key;
      this.openedsArr = [keyPath[0]]
    },
    logout(e) {
      console.log("logout", e);
      if (e == 'c') {
        localStorage.clear();
        this.$router.push("/user/login");
      } else if (e == 'a') {
        this.$router.push('/user_organ/check')
      } else if (e == 'b') {
        this.$router.push('/user/password')
      } else if (e == 'd') {
        this.$router.push('/bind_mobile')
      }
    },
    jump() {
      window.open('https://saas.tuoyufuwu.org.cn/cscpa/index.html#/autologin?username=' + this.username)
    },
    updateMenu(to) {
      console.log(to)
      let p = to.path;
      if (p.indexOf('index') != -1) {
        this.activeMenu = 'index'
      } else if (p.indexOf('chart') != -1) {
        this.activeMenu = 'chart'
      } else if (p.indexOf('table') != -1) {
        this.activeMenu = to.params.type
      } else if(p.indexOf('create') != -1){
        console.log('create')
        console.log(p.split('/').pop())
        this.activeMenu = p.split('/').pop()
      }
      console.log(this.activeMenu)
    }
  },
  mounted() {
    this.role = localStorage.getItem("role") * 1;
    console.log(this.role)
    this.menuList = this.menuAll[this.role]
   console.log(this.menuList)
    this.name = localStorage.getItem("name");
    this.username = localStorage.getItem("username");
    this.activeMenu = this.$route.name
    // console.log('mounted', this.username)
    // this.updateMenu();

  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    console.log(to)
    this.updateMenu(to);
    sessionStorage.setItem('backPage', '');
    next()
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f3f6fb;
  min-height: 100vh;
  .menu-icon {
    width: 18px;
    margin: 0 10px;
  }

  .silde {
    padding-top: 78px;
    background: #fff;
    padding-bottom: 50px;
  }
  .myContainer{
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .myHeader{
    height: 60px;
    flex-shrink: 0;
  }
  .header {
    position: fixed;
    background-color: #fff;
    color: #333;
    box-shadow: 0px 6px 13px 0px rgba(22, 127, 204, 0.13);
    z-index: 9;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    top: 0;
    padding: 0;
    color: rgb(81, 90, 110);
    font-size: 16px;
    height: 68px;

    .logo {
      margin: 4px 10px 0 20px;
      height: 40px;
      cursor: pointer;
    }

    .collapse_logo {
      height: 40px;
      margin: 0 20px;
    }

    .title {
      flex: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .collapse {
        margin: 0 30px;
        font-size: 18px;
        cursor: pointer;
        &:hover{
          color: rgb(64, 158, 255);
        }
      }

      .qa {
        cursor: pointer;
        display: flex;
        align-items: center;

        .qa-icon {
          margin: 0 8px;
          width: 18px;
        }
      }
    }

    .user {
      // flex: 0 0 350px;
      margin: 0 40px 0 20px;

      .user-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
      }

      .avatar {
        margin-right: 12px;
        flex: 0 0 38px;
      }

      .user-name {
        max-width: 200px;
      }
    }
  }

  .main {
    padding-top: 18px;
    padding-bottom: 0;
  }
  .chartMain{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0;
    overflow: hidden;
  }
  .drawer {
    width: 368px;
    padding: 0 38px;
    text-align: center;

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center;
    }

    >img {
      cursor: pointer;
    }

    p {
      color: 000;
      font-size: 16px;
    }

    .book {
      margin-top: 26px;
      margin-bottom: 42px;
      text-align: center;

      span {
        color: #0083f5;
        cursor: pointer;
      }
    }

    .pdf {
      display: flex;
      align-items: center;
      justify-content: center;

      a,
      span {
        color: #0083f5;
        text-decoration: none;
      }

      span {
        display: inline-block;
        height: 18px;
        background: #d2d2d2;
        width: 1px;
        margin: 0 22px;
      }

      a:hover {
        color: #333;
      }
    }

    h4 {
      font-size: 18px;
      margin-bottom: 17px;
      text-align: center;
    }

    .user {
      text-align: center;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
          width: 42px;
          margin-right: 7px;
        }
      }

      p {
        font-size: 14px;
        margin: 20px auto;
        text-align: center;
      }

      .qr {
        width: 130px;
      }

      p:last-of-type {
        margin-top: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.home {

  .el-submenu__title,
  .el-menu-item {
    text-align: left;
    display: flex;
    align-items: center;
  }

  .el-submenu .el-menu-item {
    text-align: left;
    padding-left: 58px !important;
  }

  .close-menu {

    .el-submenu__title>span,
    .el-submenu .el-menu-item,
    .el-menu-item>span {
      display: none !important;
    }
  }

  .el-menu {
    border-right: none;
  }

  .el-avatar {
    background: #fff;
  }
}
</style>
